import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerImage from "../img/banner.jpg"
import HomeBanner01 from "../img/home-banner-01.jpg"
import HomeBanner02 from "../img/home-banner-02.jpg"
import HomeBanner03 from "../img/home-banner-03.jpg"
import HomeBanner04 from "../img/home-banner-04.jpg"
import productImage01 from "../img/products/product01.jpg"
export default function AboutUs() {
  return (
    <div>
       <Helmet>
        <title>About Us - Represent Corporate Giftings</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="About Us - Represent Corporate Giftings" />
        <meta
          name="description"
          content="We create products through our personalized approach to empower your brand messengers, creating a strong brand recall and positioning."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://representgiftings.com/about-us/" />
        <meta
          property="og:description"
          content="We create products through our personalized approach to empower your brand messengers, creating a strong brand recall and positioning."
        />
      </Helmet>
      <Layout>
        <div className="pageHeader">
          <h1>About Us</h1>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-7 ">
              <h1>WHO WE ARE:</h1>
              <h3>WE ARE GIFT-A-HOLICS</h3>
              <p>
                <br></br>Budding entrepreneurs with a team of young minds having
                a handful of experience in advertising & operations to ensure a
                perfect combo of branding and execution. We understand the
                nuances of branding through stakeholders which leverages brand
                recall, visibility & engagement at every level.
              </p>
            </div>
            <div className="col-md-5 serviceImages">
              <img src="https://representgiftings.com/images/who-we-are.jpg"></img>
            </div>
          </div>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-5 serviceImages">
              <img src="https://representgiftings.com/images/what-we-do.jpg"></img>
            </div>
            <div className="col-md-7 ">
              <h1>WHAT WE DO</h1>
              <h3>3 W’s- OUR MANTRA</h3>
              <p>
                <br></br>
                <b>Why?</b> We understand the purpose/occasion for gifting and
                curate relevant products.<br></br>
                <br></br> <b>What?</b> - We believe in transparency and
                understand the requirement & budget, thereby suggesting products
                accordingly.
                <br></br>
                <br></br> <b>When?</b> We are committed to timelines and
                deadlines as we have been doing professionally all our career.
              </p>
            </div>
          </div>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-7 ">
              <h1>WHY US</h1>
              <h3>WE LEAVE YOUR MARK…LITERALLY</h3>
              <p>
                <br></br>
                Customization is our forte and provides end-end solutions in
                terms of products, design, and delivery. We are always on the
                lookout and ensure value through thoughtfully curated products.
              </p>
            </div>
            <div className="col-md-5 serviceImages">
              <img src="https://representgiftings.com/images/why-us.jpg"></img>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
